import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import './QRCodeComponent.css';
import logo from './../Logos/AlegraLogoNegro.png'; // Asegúrate de que la ruta al logo sea correcta

const QRCodeComponent = ({ url }) => {
  return (
    <div className="qr-wrapper">
      <div className="qr-container">
        <img src={logo} alt="Logo" className="qr-logo" /><br/>
        <QRCodeCanvas value={url} className="qr-code" />
        <p className="qr-text">Escanea el QR para visitar:<p>www.allegra-club.com</p></p> 
      </div>
    </div>
  );
};

export default QRCodeComponent;
